import axios from "axios";

const API_BASE_URL = "https://api-v3.draft5.gg";
// const API_BASE_URL = "http://localhost:8080";

export default class BetsService {
  static getLootBetOdds() {
    const url = `${API_BASE_URL}/bets/lootBet`;

    return axios.get(url);
  }

  static getBetwayOdds() {
    const url = `${API_BASE_URL}/bets/betway`;

    return axios.get(url);
  }
}
