import { useEffect, useState } from "react";
import BetwayLiveOdds from "./components/BetwayLiveOdds";

function App() {
  const [position, setPosition] = useState(1);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const position = urlParams.get("position");

    if (position) {
      setPosition(position);
    }
  }, []);

  return (
    <div className="App">
      <BetwayLiveOdds order={position} />
    </div>
  );
}

export default App;
