import FontAwesome from "react-fontawesome";
import styled from "styled-components";

const LoadingIndicator = (props) => (
  <LoadingIndicatorContainer>
    <FontAwesome name="spinner" pulse />
    <LoadingText white={props.white}>{props.text}</LoadingText>
  </LoadingIndicatorContainer>
);

const LoadingIndicatorContainer = styled.div`
  font-size: 24px;
  color: #0084ff;
  text-align: center;
  padding: 24px 0;
`;

const LoadingText = styled.p`
  font-size: 12px;
  color: ${(props) => (!props.white ? "#333" : "white")};
`;

export default LoadingIndicator;
