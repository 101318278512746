import moment from "moment";
import { useEffect, useState } from "react";
import styled from "styled-components";
import BetsService from "../services/bets";
import LoadingIndicator from "./LoadingIndicator";

const BetwayLiveOdds = ({ order }) => {
  const [loading, setLoading] = useState(true);
  const [odds, setOdds] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    BetsService.getBetwayOdds()
      .then((response) => {
        setOdds(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError("Ocorreu um erro. Por favor tente novamente mais tarde.");
      });
  }, []);

  return (
    <LiveOddsContainer>
      <LogoContainer>
        <img
          src="https://static.draft5.gg/partners/betway/logo.webp"
          width="90"
          alt="Betway"
        />
      </LogoContainer>
      <BetListContainer>
        {/* =================== */}
        {/* Loading State */}
        {/* =================== */}
        {loading && (
          <GenericContainer>
            <LoadingIndicator />
          </GenericContainer>
        )}

        {/* =================== */}
        {/* Error State */}
        {/* =================== */}
        {!loading && error && <GenericContainer>{error}</GenericContainer>}

        {/* =================== */}
        {/* Empty State */}
        {/* =================== */}
        {!loading && odds.length === 0 && !error && (
          <GenericContainer>Nenhuma partida disponível.</GenericContainer>
        )}

        {!loading &&
          odds.length > 0 &&
          odds.slice(order * 3, order * 3 + 3).map((event) => (
            <BetContainer key={event.link}>
              <BetListHeader>
                {moment(event.startDate).format("DD/MM - HH:mm")}
                <a href={event.link} target="_blank" rel="noopener noreferrer">
                  Ver Partida
                </a>
              </BetListHeader>
              <BetList>
                <Bet cellPadding="0">
                  <tbody>
                    <tr>
                      <td width="70%">{event.market.homeTeam.name}</td>
                      <td width="30%">
                        <a
                          href={event.market.homeTeam.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {event.market.homeTeam.odd}
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td width="70%">{event.market.awayTeam.name}</td>
                      <td width="30%">
                        <a
                          href={event.market.awayTeam.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {event.market.awayTeam.odd}
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </Bet>
                <EventName>{event.event}</EventName>
              </BetList>
            </BetContainer>
          ))}
        <Disclaimer>
          18+ Jogue com responsabilidade. <br />
          Odds sujeitas a alteração
        </Disclaimer>
      </BetListContainer>
    </LiveOddsContainer>
  );
};

const LiveOddsContainer = styled.div`
  background: #c7c7c7;
  font-family: "Arial", sans-serif;
`;

const EventName = styled.div`
  text-align: center;
  font-size: 12px;
  background: #c7c7c7;
  padding: 5px 0;
`;

const BetListHeader = styled.div`
  background: #eaeaea;
  font-weight: bold;
  border-bottom: 1px solid #c7c7c7;
  padding-left: 12px;
  font-size: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #333;

  a {
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    background: #833594;
    padding: 6px;
    min-width: 82px;
    text-align: center;
    transition: all 0.2s;
    width: 28%;

    span {
      margin-left: 4px;
    }

    &:hover {
      background: #993ead;
    }
  }
`;

const BetListContainer = styled.div`
  padding: 15px;
`;

const BetList = styled.div`
  background: white;
`;

const BetContainer = styled.div`
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0px;
  }
`;

const Bet = styled.table`
  font-size: 12px;
  color: #333;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: -1px;
  /* flex: 1;
  border-bottom: 1px solid #eaeaea; */

  tr td {
    padding: 10px;
    border: 1px solid #eaeaea;
  }

  tr td:last-child {
    padding: 0;
  }
  tr td:last-child a {
    padding: 10px;
    text-align: center;
    display: block;
    color: #333;
    font-weight: bold;
    transition: all 0.2s;

    &:hover {
      background: #f7f7f7;
    }
  }
`;

const LogoContainer = styled.div`
  text-align: center;
  background: #343434;
  padding: 12px;
  padding-bottom: 6px;
`;

const Disclaimer = styled.div`
  font-size: 12px;
  color: #808080;
  text-align: center;
`;

const GenericContainer = styled.div`
  background: #fff;
  margin-bottom: 15px;
  text-align: center;
  font-size: 12px;
  color: #333;
  padding: 8px;
`;

export default BetwayLiveOdds;
